@import "../../../variable.scss";

header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9dvh;

  @include background-style1;
}

.nav-elements {
  z-index: 10;

  &__ul {
    display: flex;
    text-align: center;
    justify-content: center;
    list-style-type: none;
    gap: 2.5rem;
  }
  &__link {
    font-size: 1.1rem;
    color: $primary-color-dark2;
    font-weight: bold;
  }
  &__link.active {
    padding: 0.5rem 1.5rem;
    background: $primary-color-dark2;
    border-radius: 40px;
    color: $white;
  }
  &__btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
    position: absolute;
  }
}
@media (max-width: 1024px) {
  .nav-elements {
    position: relative;
    &__container {
      position: fixed;
      top: -100dvh;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      transition: 1s;
    }
    &__ul {
      flex-direction: column;
    }
    &__btn {
      visibility: visible;
      opacity: 1;
    }
    &__btn-close {
      top: 2rem;
      right: 2rem;
    }
    &__link {
      font-size: 1.7rem;
    }
  }
  .responsive_nav {
    transform: translateY(100dvh);
    height: 100dvh;
    @include background-style1;
  }
}
