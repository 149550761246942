@import "../../variable.scss";

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90dvh;
  @include background-style3;
  &__content {
    text-align: center;
    padding: 3.1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: $white;
    max-width: 50rem;
  }
  &__error-icon {
    font-size: 4rem;
    margin-bottom: 1.2rem;
    color: $alert; /* Red color for the error icon */
  }
  &__h1 {
    font-size: 3rem;
    margin-bottom: 1.2rem;
    color: $grey2;
  }
  &__p {
    font-size: 1.2rem;
    margin-bottom: 1.8rem;
    color: $grey3;
  }
  &__home-link {
    display: inline-block;
    padding: 10px 1.2rem;
    background-color: $primary-color;
    color: $white;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color 0.3s;
  }

  &__home-link:hover {
    background-color: darken($primary-color, 10%);
    color: $white;
  }
  &__home-icon {
    margin-right: 0.6rem;
  }
}

@media (max-width: 840px) {
  .error-container {
    &__content {
      width: 80%;
    }
    &__h1 {
      font-size: 2.4rem;
    }
  }
}

@media (max-width: 600px) {
  .error-container {
    &__h1 {
      font-size: 2.2rem;
    }
    &__p {
      font-size: 1rem;
    }
  }
}
@media (max-width: 480px) {
  .error-container {
    &__h1 {
      font-size: 2rem;
    }
    &__p {
      font-size: 0.8rem;
    }
  }
}
