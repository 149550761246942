.language-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;

  &__select {
    padding: 0.2rem 0.5rem;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    outline: none;

    &:hover {
      border-color: #555;
    }

    &:focus {
      outline: none;
    }
  }
}
