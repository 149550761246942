@import "../../../variable.scss";
.spinner-container {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  @include background-style1;
  position: relative;

  &__spinner {
    width: 9rem;
    height: 9rem;
    border: 1rem solid;
    border-color: $primary-color transparent;
    border-radius: 10rem;
    animation: loading 1s linear infinite;
  }
  &__no-animation {
    position: absolute;
    font-size: 1.2rem;
    font-weight: 700;
    font-style: italic;
    color: $white;
    z-index: 10;
  }
  &__no-animation-backgroud {
    position: absolute;

    background-color: rgba($primary-color, 0.6);
    border-radius: 50%;

    padding: 4rem;
  }
  &__no-animation-backgroud2 {
    position: absolute;

    background-color: ($primary-color-dark3);
    border-radius: 50%;

    padding: 3rem;
  }
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}
